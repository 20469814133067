import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateError } from '../../components/util/Helper';
import { serviceTransformerCollection } from '../../transformers/serviceTransformer';
import { createNewScheduleRequest, deleteScheduleRequest, fetchAllStaffServiceRequest, updateScheduleRequest } from './scheduleApi';


const initialState = {
  loading: false,
  fetchingData : [],
  error: {
    hasError: false,
    status: 0,
    data: null
  },
  staffServices: [],
};

export const fetchAllStaffServices = createAsyncThunk(
  'schedule/fetchAllStaffServices',
  async (payload) => {
    const response = await fetchAllStaffServiceRequest(payload.id);
    // The value we return becomes the `fulfilled` action payload
    return response.error ? response : response.data;
  }
);

export const createSchedule = createAsyncThunk(
    'schedule/createSchedule',
    async(payload) => {
        const response = await createNewScheduleRequest(payload);
        return response.error ? response : response.data;
    }
);


export const deleteSchedule = createAsyncThunk(
  'schedule/deleteSchedule',
  async(payload) => {
      const response = await deleteScheduleRequest({id: payload});
      return response.error ? response : response.data;
  }
);


export const updateSchedule = createAsyncThunk(
  'schedule/updateSchedule',
  async(payload) => {
      const response = await updateScheduleRequest(payload);
      return response.error ? response : response.data;
  }
);


export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
   
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStaffServices.pending, (state) => {
        state.fetchingData = ["fetchAllStaffServices", ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(fetchAllStaffServices.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(item => item !== "fetchAllStaffServices");
        state.loading = !!state.fetchingData.length;

        if(updateError(action.payload, state)){return;}
        const data = action.payload.services;
        state.staffServices = serviceTransformerCollection(data);
      })
      
      .addCase(createSchedule.pending, (state) => {
        state.fetchingData = ["createSchedule", ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(item => item !== "createSchedule");
        state.loading = !!state.fetchingData.length;
      })
      .addCase(deleteSchedule.pending, (state) => {
        state.fetchingData = ["deleteSchedule", ...state.fetchingData];
        state.loading = !!state.fetchingData.length;
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        state.fetchingData = state.fetchingData.filter(item => item !== "deleteSchedule");
        state.loading = !!state.fetchingData.length;
      });
  },
});


export default scheduleSlice.reducer;
