import {
  MenuOutlined,
  UserOutlined,
  FormOutlined,
  CalendarOutlined,
  LogoutOutlined,
  ScissorOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Drawer, Select, notification } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  hairdresserSelected,
  setFrontendHairdresserList,
  setFrontendUser, // isFrontendLoggedIn,
} from '../../features/frontend/frontendSlice';
import LogoutModal from '../modal/LogoutModal';
import './AuthHeader.less';
import { useTranslation } from 'react-i18next';
import { InbellezzaIcon, weblogo } from '../../components';
import {
  getCurrentLanguage,
  setCurrentLanguage,
} from '../../services/language';
import AddVoucherModal from '../modal/AddVoucherModal';
import {
  addvoucher,
  fetchUserDetailRequest,
} from '../../features/frontend/frontendApi';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function AuthHeader({ title = null, children }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userId, setuserId] = useState('');

  const location = useLocation();

  const feEvent = localStorage.getItem('fe-eventId');

  const isHairdresserSelected = useSelector(
    (state) => state.frontend.isHairdresserSelected,
  );
  const history = useHistory();

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isAddVoucherModalOpen, setIsAddVoucherModalOpen] = useState(false);

  const handleLogout = () => setIsLoggingOut(true);

  const showDrawer = () => {
    setIsNavOpen(true);
  };
  const onClose = () => {
    setIsNavOpen(false);
  };

  const openAddVoucherModal = () => {
    setIsAddVoucherModalOpen(true);
  };

  const closeAddVoucherModal = () => {
    setIsAddVoucherModalOpen(false);
  };

  const handleAddVoucher = async (code) => {
    // const res = await addvoucher(code, userId);
    // dispatch(setFrontendHairdresserList(res?.data?.result?.upcoming));

    // closeAddVoucherModal();
    try {
      const res = await addvoucher(code, userId);
      if (res?.data?.result?.upcoming) {
        dispatch(setFrontendHairdresserList(res.data.result.upcoming));
      } else {
        throw new Error('Failed to add voucher');
      }
      closeAddVoucherModal();
    } catch (error) {
      notification.error({
        // message: t('frontend:addVoucherErrorTitle'),
        description: t('frontend:addVoucherErrorMessage'),
      });
      closeAddVoucherModal();
    }
  };

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    window.location.reload();
  };

  const current = getCurrentLanguage();

  const options = [
    {
      value: 'it',
      label: t('common:italian'),
    },
    {
      value: 'en',
      label: t('common:english'),
    },
    {
      value: 'fr',
      label: t('common:french'),
    },
  ];

  useEffect(() => {
    if (feEvent) {
      dispatch(hairdresserSelected());
    }
  }, [dispatch, feEvent]);
  useEffect(() => {
    fetchUserDetailRequest().then((response) => {
      if (!response.error) {
        setuserId(response.data.id);
        dispatch(setFrontendUser(response.data));
      }
    });
  }, [dispatch]);

  const isHairdresserOrInPath = location.pathname.includes('hairdresser');
  const isbookingInPath = location.pathname.includes('choose-service');

  return (
    <>
      <div className="front-page-header">
        <div className="page-title ml-20">
          {!isbookingInPath ? (
            <img src={weblogo} alt="Logo" className="logo" />
          ) : (
            <p>{title}</p>
          )}
        </div>
      </div>
      <div className="front-page-right-header">
        <Row>
          <Col span={20}>{children}</Col>

          {!isHairdresserSelected || isHairdresserOrInPath ? (
            <Col span={2} className="flex-row-end">
              <Button
                className="add-voucher-btn"
                style={{
                  backgroundColor: 'transparent',
                  border: 'solid white 1px',
                  borderRadius: '20px',
                  color: 'white',
                  marginRight: '10px',
                }}
                onClick={openAddVoucherModal}
              >
                {t('frontend:addVoucher')}
              </Button>
              <Select
                defaultValue={current}
                onChange={changeLanguage}
                style={{
                  backgroundColor: 'transparent',
                  border: 'solid white 1px',
                  borderRadius: '20px',
                  color: 'white',
                  minWidth: '90px',
                }}
                className="language-select"
                bordered={false}
                options={options.map((option) => ({
                  ...option,
                  label: <span>{option.label}</span>,
                }))}
              />
            </Col>
          ) : (
            <Col span={2} className="flex-row-end">
              <Select
                defaultValue={current}
                onChange={changeLanguage}
                style={{
                  backgroundColor: 'transparent',
                  border: 'solid white 1px',
                  borderRadius: '20px',
                  color: 'white',
                  minWidth: '90px',
                }}
                className="language-select"
                bordered={false}
                options={options.map((option) => ({
                  ...option,
                  label: <span>{option.label}</span>,
                }))}
              />
            </Col>
          )}
          <Col span={2} className="flex-row-end">
            {isHairdresserSelected ? (
              <div className="nav-dropdown">
                <Button size="large" className="nav-icon" onClick={showDrawer}>
                  <MenuOutlined />
                </Button>

                <Drawer
                  title="Menu"
                  placement="right"
                  onClose={onClose}
                  open={isNavOpen}
                  bodyStyle={{
                    backgroundColor: '#dbdce1',
                  }}
                  headerStyle={{
                    backgroundColor: '#4a4e69',
                    minHeight: '61px',
                  }}
                >
                  <p>
                    <Link
                      to="/choose-service"
                      className="nav-links"
                      data-testid="book-service"
                    >
                      <FormOutlined className="iconMenu" />
                      {t('frontend:authPage.book')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      to="/reservation"
                      className="nav-links"
                      data-testid="notification"
                    >
                      <CalendarOutlined className="iconMenu" />
                      {t('frontend:authPage.my_appointments')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      to="/user-profile"
                      className="nav-links"
                      data-testid="profile"
                    >
                      <UserOutlined className="iconMenu" />
                      {t('frontend:authPage.profile')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      to="/hairdresser"
                      className="nav-links"
                      data-testid="hairdresser"
                    >
                      <ScissorOutlined className="iconMenu" />
                      {t('frontend:authPage.hairdresser')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      to="#"
                      onClick={handleLogout}
                      className="nav-links"
                      data-testid="logout"
                    >
                      <LogoutOutlined className="iconMenu" />
                      {t('frontend:authPage.logout')}
                    </Link>
                  </p>

                  <img
                    src={InbellezzaIcon}
                    alt={'Inbellezza icon'}
                    style={{
                      position: 'absolute',
                      bottom: '16px',
                      width: '120px',
                      left: '230px',
                    }}
                  />
                </Drawer>
              </div>
            ) : (
              <div className="nav-dropdown">
                <Button className="btnGhost" ghost={true} onClick={showDrawer}>
                  <UserOutlined style={{ fontSize: '22px', color: '#fff' }} />
                </Button>
                <Drawer
                  title="Menu"
                  placement="right"
                  onClose={onClose}
                  open={isNavOpen}
                  bodyStyle={{
                    backgroundColor: '#dbdce1',
                  }}
                  headerStyle={{
                    backgroundColor: '#4a4e69',
                    minHeight: '61px',
                  }}
                >
                  <p>
                    <Link
                      to="/user-profile"
                      className="nav-links"
                      data-testid="profile"
                    >
                      <UserOutlined className="iconMenu" />
                      {t('frontend:authPage.profile')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      to="/hairdresser"
                      className="nav-links"
                      data-testid="hairdresser"
                    >
                      <ScissorOutlined className="iconMenu" />
                      {t('frontend:authPage.hairdresser')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      to="#"
                      onClick={handleLogout}
                      className="nav-links"
                      data-testid="logout"
                    >
                      <LogoutOutlined className="iconMenu" />
                      {t('frontend:authPage.logout')}
                    </Link>
                  </p>
                  <img
                    src={InbellezzaIcon}
                    alt={'Inbellezza icon'}
                    style={{
                      position: 'absolute',
                      bottom: '16px',
                      width: '120px',
                      left: '230px',
                    }}
                  />
                </Drawer>
              </div>
            )}
          </Col>
        </Row>
        <LogoutModal
          closeModal={() => setIsLoggingOut(false)}
          onLogout={() => {
            localStorage.removeItem('fe-eventId');
            if (!localStorage.getItem('fe-eventId')) {
              history.push('/login');
            }
          }}
          open={isLoggingOut}
        />
        <AddVoucherModal
          open={isAddVoucherModalOpen}
          closeModal={closeAddVoucherModal}
          handleOK={handleAddVoucher} // Pass handleAddVoucher function
        />
      </div>
    </>
  );
}

AuthHeader.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
};

export default AuthHeader;
