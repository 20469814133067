import { Select, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { authRequest } from '../../services/api';
import { logHandler } from '../../handlers/LogHandler';
import './ServiceStaffSelect.less';
import { t } from 'i18next';

function ServiceStaffSelect({
  serviceId,
  onSelect,
  defaultValue = 0,
  getOptions = null,
}) {
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState([]);
  const [loading, setLoading] = useState(true);

  const getOptionsRef = useRef(getOptions);

  useEffect(() => {
    getOptionsRef.current = getOptions;
  }, [getOptions]);

  useEffect(() => {
    let eventId = localStorage.getItem('fe-eventId');
    const defaultOption = {
      id: 0,
      label: `${t('frontend:reservationpage.anyone')}`,
      value: 0,
    };
    if (typeof serviceId === undefined || serviceId === null) {
      return;
    }
    setLoading(true);
    authRequest()
      .get(
        `/api/business/v1/events/${eventId}/services/${serviceId}?locale=${localStorage.getItem(
          'lang',
        )}`,
      )
      .then((res) => {
        let allOptions = res.data.staffs.map((item) => ({
          id: item.id,
          label: item.name,
          value: item.id,
        }));
        allOptions = [...allOptions, defaultOption];
        setOptions(allOptions);
        setSearch(allOptions);
        setLoading(false);

        if (getOptionsRef.current) {
          getOptionsRef.current(allOptions);
        }
      })
      .catch((error) => {
        setOptions([defaultOption]);
        setSearch([defaultOption]);
        setLoading(false);
        logHandler.error(error);
      });
  }, [serviceId]);

  const onChange = (value) =>
    onSelect && typeof onSelect === 'function' && onSelect(value);

  const onSearch = (keyword) =>
    setSearch(
      options.filter(
        (item) => item.label.toLowerCase().indexOf(keyword.toLowerCase()) >= 0,
      ),
    );

  return (
    <>
      {loading ? (
        <Spin size="small" />
      ) : (
        <Select
          showSearch
          defaultValue={defaultValue}
          placeholder="Select a person"
          className="selectClass"
          bordered={false}
          style={{
            minWidth: 150,
          }}
          optionFilterProp="children"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={false}
          options={search}
        />
      )}
    </>
  );
}

ServiceStaffSelect.propTypes = {
  serviceId: PropTypes.number,
  onSelect: PropTypes.func,
};

export default ServiceStaffSelect;
