import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Checkbox,
  Select,
  Spin,
} from 'antd';
import 'antd/dist/antd.css';
import './FeRegister.less';
import { weblogo } from '../../components';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { frontendLogout } from '../../features/frontend/frontendSlice';
import { t } from 'i18next';
import {
  getCurrentLanguage,
  setCurrentLanguage,
} from '../../services/language';
import { postFrontendRegisterRequest } from '../../features/frontend/frontendApi';
import { parseApiResponseAndSendNotification } from '../../handlers/ApiErrorNotifier';
import { NOTIFICATION_ACTION } from '../../app/constants';

const FeRegister = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const current = getCurrentLanguage();

  const options = [
    {
      value: 'it',
      label: t('common:italian'),
    },
    {
      value: 'en',
      label: t('common:english'),
    },
    {
      value: 'fr',
      label: t('common:french'),
    },
  ];

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
    window.location.reload();
  };

  const registerUser = (data) => {
    setLoading(true);
    postFrontendRegisterRequest(data)
      .then((res) => {
        if (res?.response) {
          parseApiResponseAndSendNotification(
            res.response,
            NOTIFICATION_ACTION,
          );
        }

        if (res.data.status === 200) {
          notification['success']({
            message: res.data.message,
          });
          history.push('/login');
        }

        frontendLogout();
      })
      .catch((err) => {
        frontendLogout();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="register-container" style={{ backgroundColor: '#4A4E69' }}>
      <div className="logo-container">
        <img src={weblogo} alt="Logo" className="logo" />
      </div>
      <div className="register-form">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '25px',
          }}
        >
          <h2> {t('frontend:authPage.register')}</h2>
          <Select
            defaultValue={current}
            onChange={changeLanguage}
            style={{
              backgroundColor: 'transparent',
              border: 'solid #D4D4D4 1px',
              color: '#7D7D7D',
              maxWidth: '100px',
              maxHeight: '30px',
            }}
            className="language-select"
            bordered={false}
            options={options.map((option) => ({
              ...option,
              label: <span>{option.label}</span>,
            }))}
          />
        </div>
        <Form
          name="register"
          initialValues={{ remember: true }}
          onFinish={registerUser}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input
                  className="custom-input"
                  placeholder={`${t('calendar.name')}`}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="surname"
                label="Surname"
                rules={[{ required: true }]}
              >
                <Input
                  className="custom-input"
                  placeholder={`${t('calendar.surname')}`}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, type: 'email' }]}
              >
                <Input className="custom-input" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Phone number"
                rules={[{ required: true }]}
              >
                <Input
                  className="custom-input"
                  placeholder={`${t('calendar.phone_number')}`}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="username"
                label="Username"
                rules={[{ required: true }]}
              >
                <Input className="custom-input" placeholder="Username" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true }]}
              >
                <Input.Password
                  className="custom-input"
                  placeholder="Password"
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                labelAlign="left"
                name={'terms_and_conditions'}
                valuePropName="checked"
                rules={[{ required: true }]}
              >
                <Checkbox value={true}>
                  {t('frontend:authPage.policy_accept')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {loading ? (
              <Spin size="small" />
            ) : (
              <Button type="primary" htmlType="submit" block>
                {t('frontend:authPage.register')}
              </Button>
            )}
          </Form.Item>
        </Form>
        <Link to="/Login">
          <p>
            {t('frontend:authPage.already_account')} <b>Login</b>
          </p>
        </Link>
      </div>
    </div>
  );
};

export default FeRegister;
