import {
  Avatar,
  Button,
  Col,
  Empty,
  Input,
  List,
  Row,
  Typography,
  Pagination,
  Radio,
  Popover,
  Checkbox,
  Select,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  CREATE_MODE,
  INITIAL_MODE,
  NOTIFICATION_DELETED,
  NOTIFICATION_UPDATED,
  SHOW_MODE,
} from '../app/constants';
import { sortByKey } from '../components/util/Helper';
import {
  parseUrlAndSetParam,
  updateRouteParams,
} from '../components/util/RouteHelper';
import {
  createCustomer,
  fetchCustomer,
  fetchCustomerListQ,
  fetchCustomerTimeline,
  removeCustomer,
  selectCustomerList,
  selectCustomerLoading,
  selectCustomerProfile,
  selectCustomerProfileTimeline,
  updateCustomer,
} from '../features/customer/customerSlice';
import { parseApiResponseAndSendNotification } from '../handlers/ApiErrorNotifier';
import { logHandler } from '../handlers/LogHandler';
import {
  //AddIcon,
  ClientForm,
  CustomerDetail,
  DefaultPageLayout,
  SearchIcon,
  StaffIcon,
  UserPlaceholder,
} from './../components/index';
import { getApiFormatFromFormData } from '../transformers/participantTransformer';
import { PlusCircleFilled, SettingFilled } from '@ant-design/icons';
import './CustomerRegisterPage.less';

const { Paragraph } = Typography;

function CustomerRegisterPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentTime, setCurrentTime] = useState(moment());
  const clients = useSelector(selectCustomerList);
  const loading = useSelector(selectCustomerLoading);
  const timeline = useSelector(selectCustomerProfileTimeline);
  const profile = useSelector(selectCustomerProfile);
  const [tab, setTab] = useState('personal');
  const [clientList, setClientList] = useState([]);
  const [mode, setMode] = useState(INITIAL_MODE);
  // search
  const [searchKeyword, setSearchKeyword] = useState('');
  const [begin, setBegin] = useState(1);
  const [pageSearch, setPageSearch] = useState(1);
  const [perPage, setPerPage] = useState(
    parseInt(localStorage.getItem('per_page') || '15'),
  );
  const [numRows, setNumRows] = useState(0);
  const [showTotal, setShowTotal] = useState(
    parseInt(localStorage.getItem('showTotal') || '0') ? true : false,
  );

  const history = useHistory();

  const clientClick = useCallback(
    (item) => {
      setMode(INITIAL_MODE);
      dispatch(fetchCustomer({ id: item.id }))
        .then((res) => {
          updateRouteParams(
            { client: item.id },
            parseUrlAndSetParam({}),
            history,
          );
          dispatch(fetchCustomerTimeline({ id: item.id }));
          setMode(SHOW_MODE);
        })
        .catch((error) => {
          logHandler.error(error);
        });
    },
    [dispatch, history],
  );

  const fetchApiData = useCallback(
    (param) => {
      dispatch(fetchCustomerListQ(param))
        .then((res) => {
          const num = res?.payload?.totals?.rows || 0;
          setNumRows(num);

          let urlParams = parseUrlAndSetParam({});

          if (!urlParams.has('client')) {
            return;
          }
          if (urlParams.has('tab')) {
            setTab(urlParams.get('tab'));
          }

          let clientId = urlParams.get('client');

          res.payload.participants.forEach(
            (item) => String(item.id) === String(clientId) && clientClick(item),
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [clientClick, dispatch],
  );

  const deleteCustomer = () => {
    if (!profile) return;
    dispatch(removeCustomer({ id: profile.id }))
      .then((res) => {
        setMode(INITIAL_MODE);
        parseApiResponseAndSendNotification(res, NOTIFICATION_DELETED);
      })
      .catch((error) => {
        logHandler.error(error);
        setMode(SHOW_MODE);
        parseApiResponseAndSendNotification(error, NOTIFICATION_DELETED);
      });
  };

  useEffect(() => {
    //if (!clients.length) return;
    setClientList(sortByKey(clients, 'name'));
  }, [clients]);

  useEffect(() => {
    let interval = null;
    let timeout = setTimeout(
      () => {
        interval = setInterval(() => {
          setCurrentTime(moment());
        }, 60000);
      },
      60 - moment().format('s'),
    );

    return () => {
      interval && clearInterval(interval);
      timeout && clearTimeout(timeout);
    };
  }, []);

  const runSearch = () => {
    const param = {
      keyword: searchKeyword,
      begin: begin - 1 ? false : true,
      page: pageSearch,
      per_page: perPage,
    };

    //console.log('param', param);
    fetchApiData(param);
  };

  useEffect(() => {
    runSearch();
    // eslint-disable-next-line
  }, [pageSearch]);

  const chgBegin = (e) => {
    setBegin(e.target.value);
  };

  const onSearch = (event) => setSearchKeyword(event.target.value);

  const updateUserData = (data) => {
    let apiData = getApiFormatFromFormData(data);
    apiData = { participant: { id: profile.id, ...apiData.participant } };
    dispatch(updateCustomer(apiData))
      .then((res) => {
        parseApiResponseAndSendNotification(res, NOTIFICATION_UPDATED);
      })
      .catch((err) => {
        logHandler.error(err);
        parseApiResponseAndSendNotification(err, NOTIFICATION_UPDATED);
      });
  };

  const showCreateForm = () => {
    setMode(CREATE_MODE);
    updateRouteParams(
      { tab: null, client: null },
      parseUrlAndSetParam({}),
      history,
    );
  };

  const createUser = (data) => {
    dispatch(createCustomer(data))
      .then((res) => {
        setMode(INITIAL_MODE);
        showCreateForm();
      })
      .catch((err) => {
        logHandler.error(err);
      });
  };

  const shouldLoadSidebar = () =>
    loading.some((state) =>
      ['fetchCustomerList', 'removeCustomer', 'createCustomer'].includes(state),
    );
  const isPageLoading = () => !!loading.length;

  const handleTabChange = (key) => {
    setTab(key);
    updateRouteParams({ tab: key }, parseUrlAndSetParam({}), history);
  };

  const settingContent = () => {
    return (
      <>
        <div style={{ marginTop: '8px' }}>
          <Checkbox onChange={updateShowTotal}>
            {t('clientpage.show_found_items')}
          </Checkbox>
        </div>
        <div style={{ marginTop: '16px' }}>
          <div style={{ marginBottom: '6px' }}>
            {t('clientpage.item_per_page')}
          </div>
          <Select
            value={perPage}
            style={{
              width: '180px',
            }}
            onChange={changePerPage}
            options={[
              {
                value: 5,
                label: '5',
              },
              {
                value: 10,
                label: '10',
              },
              {
                value: 15,
                label: '15',
              },
              {
                value: 20,
                label: '20',
              },
              {
                value: 25,
                label: '25',
              },
              {
                value: 30,
                label: '30',
              },
              {
                value: 50,
                label: '50',
              },
            ]}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    if (pageSearch === 1) runSearch();
    else setPageSearch(1);
    // eslint-disable-next-line
  }, [perPage]);

  const changePerPage = (v) => {
    setPerPage(v);
    localStorage.setItem('per_page', v);
  };

  const updateShowTotal = (e) => {
    const v = showTotal ? false : true;
    localStorage.setItem('showTotal', v ? '1' : '0');
    setShowTotal(v);
  };

  return (
    <DefaultPageLayout
      loadingContent={isPageLoading()}
      loadingSidebar={shouldLoadSidebar()}
    >
      <DefaultPageLayout.Title>
        <div className="page-title-ag">
          <span style={{ paddingRight: '12px' }}>
            <svg
              style={{ position: 'relative' }}
              width="24"
              height="24"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="3"
                width="12"
                height="16"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 3.07495H11.9175V18.9999H0V3.07495Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.48145 18.3072C1.8352 15.0459 2.87345 13.4375 4.95267 12.9294C5.28027 12.8494 5.51598 12.5479 5.5271 12.1944C5.53813 11.841 5.32203 11.5237 5.00008 11.4211C3.60115 10.975 2.66135 9.64182 2.66135 8.10377C2.66135 6.19239 4.14047 4.63731 5.95881 4.63731C7.77686 4.63731 9.25608 6.19239 9.25608 8.10377C9.25608 9.64182 8.31618 10.975 6.91725 11.4211C6.5953 11.5238 6.3792 11.841 6.39023 12.1944C6.40145 12.548 6.63706 12.8495 6.96476 12.9295C9.04418 13.4376 10.0823 15.046 10.4361 18.3072C10.4824 18.7358 10.8517 19.0441 11.2585 18.9947C11.6663 18.9459 11.9591 18.5587 11.9127 18.1301C11.6912 16.0884 11.1263 13.4209 8.91286 12.0663C10.0479 11.1364 10.7422 9.69577 10.7422 8.10377C10.7422 5.33085 8.59637 3.07495 5.95881 3.07495C3.32106 3.07495 1.1752 5.33085 1.1752 8.10377C1.1752 9.69567 1.86954 11.1364 3.00457 12.0662C0.791299 13.4209 0.226421 16.0884 0.00486032 18.13C-0.0416763 18.5587 0.251202 18.9459 0.659007 18.9947C0.687592 18.9982 0.715982 18.9999 0.74408 18.9999C1.11676 18.9999 1.43813 18.7057 1.48145 18.3072Z"
                  fill="#B19499"
                />
              </g>
              <mask
                id="mask1"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="9"
                y="0"
                width="10"
                height="16"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.06445 0.0384521H18.9953V15.9634H9.06445V0.0384521Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask1)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9156 9.02984C17.079 8.09989 17.7907 6.65927 17.7907 5.06727C17.7907 2.29445 15.5912 0.0384521 12.8877 0.0384521C11.5116 0.0384521 10.1906 0.637016 9.26319 1.6805C8.97979 1.99938 9.00219 2.49353 9.31309 2.78409C9.62389 3.07466 10.1055 3.05179 10.389 2.73302C11.0283 2.01353 11.9391 1.60081 12.8877 1.60081C14.7513 1.60081 16.2674 3.15589 16.2674 5.06727C16.2674 6.60532 15.304 7.93845 13.8701 8.3845C13.5402 8.48717 13.3186 8.8044 13.33 9.15794C13.3414 9.51137 13.5829 9.81291 13.9188 9.89302C16.0502 10.4011 17.1142 12.0094 17.4769 15.2707C17.5211 15.6692 17.8506 15.9634 18.2326 15.9634C18.2615 15.9634 18.2906 15.9616 18.3198 15.9582C18.7378 15.9094 19.038 15.5222 18.9903 15.0936C18.7634 13.0519 18.1843 10.3845 15.9156 9.02984Z"
                  fill="#B19499"
                />
              </g>
            </svg>
          </span>
          {t('clientpage.navbar_title')}
        </div>
      </DefaultPageLayout.Title>

      <DefaultPageLayout.Header>
        <div className="lead">{currentTime.format('HH:mm')}</div>
      </DefaultPageLayout.Header>

      <DefaultPageLayout.Sidebar>
        <div className="cl_radioSetting">
          <Radio.Group onChange={chgBegin} value={begin}>
            <Radio value={1}>{t('clientpage.starts_with')}</Radio>
            <Radio value={2}>{t('clientpage.contains')}</Radio>
          </Radio.Group>

          <Popover
            trigger="click"
            placement="rightTop"
            title={t('clientpage.setting')}
            content={settingContent}
          >
            <Button
              ghost={true}
              className="cl_btnSetting"
              icon={<SettingFilled className="cl_gear" />}
            ></Button>
          </Popover>
        </div>

        <Row>
          <Col span={19}>
            <Input
              placeholder={t('clientpage.search_placeholder')}
              size="large"
              onChange={onSearch}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (pageSearch === 1) runSearch();
                  else setPageSearch(1);
                }
              }}
            />
          </Col>
          <Col span={5}>
            <Button
              size="large"
              onClick={() => {
                if (pageSearch === 1) runSearch();
                else setPageSearch(1);
              }}
              className="cl_btnSearch"
            >
              <img
                src={SearchIcon}
                alt="Search Services"
                style={{ height: '18px' }}
              />
            </Button>
          </Col>
        </Row>

        <div className="cl_rowPagination">
          <Pagination
            size="small"
            current={pageSearch}
            pageSize={perPage}
            total={numRows}
            showSizeChanger={false}
            onChange={(page) => {
              setPageSearch(page);
            }}
          />
        </div>
        {showTotal && (
          <div className="cl_total">
            {t('clientpage.total_found')} {numRows}
          </div>
        )}

        <div className="mt-4"></div>
        {!!clientList.length && (
          <div className="client-list list">
            <List
              dataSource={clientList}
              renderItem={(item) => (
                <List.Item
                  className="appear-effect"
                  key={`${item.id}-${item.name}-${item.surname}`}
                  onClick={(e) => clientClick(item)}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={item.avatar || UserPlaceholder} />}
                    title={
                      <p className="text-white text-capitalize text-start normal-roboto m-0 mt-2">{`${item.name} ${item.surname}`}</p>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        )}
        {!clientList.length && (
          <div className="pt-4 mt-2">
            <Empty
              imageStyle={{
                height: 60,
              }}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span className="mt-2 text-white">
                  {t('clientpage.search_client')}
                </span>
              }
            />
          </div>
        )}
      </DefaultPageLayout.Sidebar>

      <DefaultPageLayout.Content>
        {mode === INITIAL_MODE && (
          <Row type="flex" align="center" className="w-100 vh-100 text-center">
            <Col span={24}>
              <div style={{ marginTop: 'calc(30vh - 85px)' }}>
                <Empty
                  image={<img src={StaffIcon} alt="Staff Icon" />}
                  description={
                    <div>
                      <Paragraph className="text-center mt-4 lead">
                        {t('clientpage.empty_description')}
                      </Paragraph>
                    </div>
                  }
                />
              </div>
            </Col>
          </Row>
        )}

        {mode === CREATE_MODE && (
          <div className="profile pt-4 py-4 my-4 mx-4">
            <div className="profile-header">
              <ClientForm mode={CREATE_MODE} onSubmit={createUser} />
            </div>
          </div>
        )}

        {mode === SHOW_MODE && (
          <CustomerDetail
            updateUser={updateUserData}
            timeline={timeline}
            customer={profile}
            onDelete={deleteCustomer}
            activeTab={tab}
            onTabChange={handleTabChange}
          />
        )}

        {mode !== CREATE_MODE && (
          <div className="cl_addCustomer">
            <Button size="large" onClick={showCreateForm} className="w-100">
              <div className="btn-flex">
                <PlusCircleFilled
                  twoToneColor="#B19499"
                  style={{ fontSize: '24px', paddingTop: '2px' }}
                />

                <div className="m-4">{t('clientpage.add_client')}</div>
              </div>
            </Button>
          </div>
        )}
      </DefaultPageLayout.Content>
    </DefaultPageLayout>
  );
}

export default CustomerRegisterPage;
