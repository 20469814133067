import { useState } from 'react';
import './ForzaOrarioModal.less';
import PropTypes from 'prop-types';
import { range } from '../../util/Helper';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Select, Space, Spin, Typography } from 'antd';

const { Option } = Select;

function ForzaOrarioModal({ onSubmit, loading, closeModal, staff }) {
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const { t } = useTranslation();

  function handleSubmit(e) {
    if (typeof onSubmit === 'function')
      onSubmit({
        startHour,
        startMinute,
        endHour,
        endMinute,
        type: 'submit',
      });
  }

  function handleReset() {
    if (typeof onSubmit === 'function')
      onSubmit({
        startHour,
        startMinute,
        endHour,
        endMinute,
        type: 'reset',
      });
  }

  return (
    <Spin spinning={loading} delay={300}>
      <div className="time-modal-title text-center">Forza {staff.name}</div>

      <Row>
        <div className="my-2 py-2 w-100"></div>
        <Col span={24}>
          <Typography.Paragraph
            level={5}
            className="text-center font-14 text-uppercase"
          >
            {t('timetablemodal.opening')}
          </Typography.Paragraph>
        </Col>
        <div className="my-1 py-1 w-100"></div>
        <Col span={12}>
          <Space size="large">
            <p className="p-0 timtable-text m-0">
              {t('timetablemodal.starttime')}
            </p>
            <Select
              showSearch
              defaultValue={startHour}
              style={{ width: 100 }}
              onChange={(val) => setStartHour(val)}
            >
              {range(0, 23).map((hour) => (
                <Option key={`start-${hour}`} value={hour}>
                  {hour}
                </Option>
              ))}
            </Select>
            <p className="timtable-text">:</p>
            <Select
              showSearch
              defaultValue={startMinute}
              style={{ width: 100 }}
              onChange={(val) => setStartMinute(val)}
            >
              <Option value={0}>{0}</Option>
              <Option value={15}>{15}</Option>
              <Option value={30}>{30}</Option>
              <Option value={45}>{45}</Option>
            </Select>
          </Space>
        </Col>

        <Col span={12}>
          <Space size="large">
            <p className="p-0 timtable-text m-0">
              {t('timetablemodal.endtime')}
            </p>
            <Select
              showSearch
              defaultValue={endHour}
              style={{ width: 100 }}
              onChange={(val) => setEndHour(val)}
            >
              {range(0, 23).map((hour) => (
                <Option key={`end-${hour}`} value={hour}>
                  {hour}
                </Option>
              ))}
            </Select>
            <p className="timtable-text">:</p>
            <Select
              showSearch
              defaultValue={endMinute}
              style={{ width: 100 }}
              onChange={(val) => setEndMinute(val)}
            >
              <Option value={0}>{0}</Option>
              <Option value={15}>{15}</Option>
              <Option value={30}>{30}</Option>
              <Option value={45}>{45}</Option>
            </Select>
          </Space>
        </Col>

        <div className="my-2 py-3 w-100"></div>

        <Col span={12} offset={6}>
          <p className="secondary-button-outline">
            {t('shoptimepage.pausemodal.title')}
          </p>
        </Col>
        <div className="my-3 py-2 w-100"></div>
      </Row>
      <Row>
        <Col span={6}>
          <Button
            size="large"
            shape="round"
            onClick={handleReset}
            className="px-4 w-50 secondary-button-outline"
          >
            {t('common:button.reset')}
          </Button>
        </Col>

        <Col span={12} className="text-center">
          <Button
            size="large"
            onClick={handleSubmit}
            className="secondary-button-outline btn px-4"
          >
            {t('common:button.save')}
          </Button>
        </Col>
      </Row>
      <div className="my-2 py-2 w-100"></div>
    </Spin>
  );
}

ForzaOrarioModal.propTypes = {
  loading: PropTypes.bool,
  staff: PropTypes.object,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ForzaOrarioModal;
