import { Form, Input, Button, Checkbox, Typography, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CREATE_MODE } from '../../app/constants';
import './ClientForm.less';

/**
 * Client update or create form
 * @param {Object} param0
 * @returns
 */
function ClientForm({ initialValue, onSubmit, mode, customLayout = {} }) {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const onFinishFailed = (errorInfo) => {
    //Handle failure
  };

  const layout = {
    labelCol: {
      offset: customLayout?.labelCol?.offset || 2,
      span: customLayout?.labelCol?.span || 6,
    },
    wrapperCol: { span: customLayout?.wrapperCol?.span || 10 },
  };

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [form, initialValue]);

  return (
    <div>
      <Form
        {...layout}
        form={form}
        initialValues={initialValue}
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          labelAlign="left"
          name={'name'}
          label={t('clientpage.label.name')}
          rules={[{ required: true }]}
        >
          <Input size="large" placeholder={t('clientpage.placeholder.name')} />
        </Form.Item>

        {initialValue?.username && (
          <Form.Item labelAlign="left" label={t('clientpage.label.username')}>
            <Input size="large" value={initialValue.username} disabled={true} />
          </Form.Item>
        )}

        <Form.Item
          labelAlign="left"
          name={'surname'}
          label={t('clientpage.label.surname')}
          rules={[{ required: true }]}
        >
          <Input
            size="large"
            placeholder={t('clientpage.placeholder.surname')}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          name={'address'}
          label={t('clientpage.label.address')}
        >
          <Input
            size="large"
            placeholder={t('clientpage.placeholder.address')}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          name={'postal_code'}
          label={t('clientpage.label.postal_code')}
        >
          <Input
            size="large"
            placeholder={t('clientpage.placeholder.postal_code')}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          name={'email'}
          label={t('clientpage.label.email')}
        >
          <Input
            type={'email'}
            size="large"
            placeholder={t('clientpage.placeholder.email')}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          name={'phone_number'}
          label={t('clientpage.label.phone_number')}
        >
          <Input
            size="large"
            placeholder={t('clientpage.placeholder.phone_number')}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          name={'date_of_birth'}
          label={t('clientpage.label.dob')}
        >
          <Input
            type={'date'}
            size={'large'}
            className="w-100"
            placeholder={t('clientpage.placeholder.dob')}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          name={'notes'}
          label={t('clientpage.label.notes')}
        >
          <Input.TextArea
            rows={4}
            autoComplete="off"
            size="large"
            placeholder={t('clientpage.placeholder.notes')}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          name={'terms_and_conditions'}
          valuePropName="checked"
          label={' '}
          colon={false}
        >
          <Checkbox value={true}>
            {t('clientpage.label.terms_and_condition')}
          </Checkbox>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 16,
          }}
          className="text-center"
        >
          <Button shape="round" htmlType="submit" className="btn-padding">
            {' '}
            {mode === CREATE_MODE
              ? t('clientpage.button.create')
              : t('clientpage.button.update')}
          </Button>
        </Form.Item>

        {mode === CREATE_MODE && (
          <Row>
            <Col span={22} offset={2}>
              <Typography.Paragraph>
                {t('clientpage.label.note')}
              </Typography.Paragraph>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
}

ClientForm.propTypes = {
  initialValue: PropTypes.object,
  onSubmit: PropTypes.func,
  customLayout: PropTypes.object,
};

export default ClientForm;
