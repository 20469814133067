import {
  deleteAuthRequest,
  getFrontendRequest,
  postAuthRequest,
  putAuthRequest,
} from '../../services/api';
import { getEventFromStorage } from '../../services/token';

/**
 * Send request to get all staff service
 *
 * @returns {Promise}
 */
export const fetchAllFeServiceRequest = () => {
  const eventId = localStorage.getItem('fe-eventId');
  let url = `/api/business/v1/events/${eventId}/services?locale=${localStorage.getItem(
    'lang',
  )}`;
  return getFrontendRequest(url);
};

export const fetchAllServiceRequest = () => {
  const eventId = getEventFromStorage();
  let url = `/api/business/v1/events/${eventId}/services?locale=${localStorage.getItem(
    'lang',
  )}`;
  return getFrontendRequest(url);
};

/**
 * Send request to get all staff service
 *
 * @returns {Promise}
 */
export const fetchServiceDetailRequest = (data) => {
  const eventId = localStorage.getItem('fe-eventId');
  let url = `/api/business/v1/events/${eventId}/services/${
    data.id
  }?locale=${localStorage.getItem('lang')}`;
  return getFrontendRequest(url);
};

export const fetchBoServiceDetailRequest = (data) => {
  const eventId = getEventFromStorage();
  let url = `/api/business/v1/events/${eventId}/services/${
    data.id
  }?locale=${localStorage.getItem('lang')}`;
  return getFrontendRequest(url);
};

/**
 * Send request to create a appointment
 *
 * @param {Object} data Appointment data to create new appointment
 * @returns {Promise}
 */
export const createNewServiceRequest = (data) => {
  const eventId = getEventFromStorage();
  return postAuthRequest(`/api/business/v1/events/${eventId}/services`, {
    ...data,
  });
};

/**
 * Send Update service request
 * @param {Object} data Data to update service
 * @returns {Promise}
 */
export const updateServiceRequest = (data) => {
  const eventId = getEventFromStorage();
  return putAuthRequest(
    `/api/business/v1/events/${eventId}/services/${data.id}`,
    { ...data.apiFormat },
  );
};

/**
 * Send Update service request
 * @param {Object} data Data to update service
 * @returns {Promise}
 */
export const deleteServiceRequest = (data) => {
  const eventId = localStorage.getItem('fe-eventId');
  return deleteAuthRequest(
    `/api/business/v1/events/${eventId}/services/${data.id}`,
  );
};
